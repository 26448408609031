<template>
  <div class="chat-comitentes m-t">
    <h2 class="font-16 font-bold text-grey-8 m-b-sm">Chat Comitentes</h2>
    <chat-item v-for="comitente in comitentes" :key="comitente.id" :comitente="comitente" />
  </div>
</template>

<script>

import ChatItem from './ChatItem'
export default {
  name: 'ChatComitentes',
  components: {ChatItem},
  data () {
    return {
    }
  },
  props: {
    comitentes: {
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">

</style>
