<template>
  <div>
    <div class="comitentes-online">
      <div class="list-c-online">
        <div class="lco-header"><span>Comitentes Online</span> <i>{{comitentes.length}}</i></div>
        <div class="lco-comitente" v-for="comitente in comitentes" :key="comitente.id">
          <span>{{comitente.pessoa.name}}</span> <i class="color" :style="{background: comitente.color}"></i></div>
      </div>
      <div class="m-t-lg no-select">Monitorando ações dos comitentes...</div>
    </div>

    <chat-comitentes :comitentes="comitentes" />
  </div>
</template>

<script>
import {getRandomColor} from '../../../../utils/color'
import ChatComitentes from './ChatComitente'

let colorPallete = ['#D16565', '#6589D1', '#D1BF65', '#5256B5', '#44B437', '#ad4f8b']

let getColor = function () {
  if (colorPallete.length) {
    return colorPallete.shift()
  }
  return getRandomColor()
}

export default {
  name: 'ComitentesOnline',
  components: {ChatComitentes},
  data () {
    return {
      comitentes: [
        {
          id: 1,
          apelido: 'GRUPOPORTO',
          pessoa: {
            id: 1,
            name: 'Grupo Porto',
            image: {
              thumb: 'https://claroseguros.com.br/wp-content/uploads/2019/02/seguradora-porto-azul-itau-250.jpg'
            }
          },
          notReads: 0,
          messages: [
            {
              label: 'Hoje'
            },
          ],
          color: getColor()
        }
      ]
    }
  },
  props: {
    leilao: {
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">
  .comitentes-online {
    width 100%
    background #141414
    color #31D410
    font-size 10px
    padding 8px 6px
    border-radius 5px
    margin-top 12px

    .lco-header, .lco-comitente {
      display flex
      flex-direction row
      border #45D40C 1px dashed
      padding 6px
      border-bottom none
      font-weight 600
      justify-content space-between

      span {
        flex 1
      }

      i {
        font-style normal
        color #FFFFFF
      }
    }

    .lco-header {
      text-transform uppercase
      user-select none
    }

    .lco-comitente {
      color #FFFFFF
      cursor: pointer

      &:last-child {
        border-bottom #45D40C 1px dashed
      }

      i.color {
        text-indent -5000px
        overflow hidden
        display flex
        width 10px
        height 10px
        background #D16565
      }
    }
  }
</style>
