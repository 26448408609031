<template>
  <div class="comitente-chat-item" :class="{opened: show}" :style="bindContainerStyle">
    <div @click="show = !show" class="cchat no-select" :style="bindChatStyle">
      <span class="chat-user" :style="bindNameStyle">{{comitente.pessoa.name}}</span>
      <span class="chat-msg-not-read" v-if="comitente.notReads">{{comitente.notReads}}</span>
      <span class="chat-toggle"><u-icon :name="show ? 'chevron-up' : 'chevron-down'" type="fa" /></span>
    </div>
    <div class="message-container" v-show="show">
      <u-chat-message
          v-for="(msg, index) in comitente.messages"
          :key="`reg-${index}`"
          :label="msg.label"
          :sent="msg.sent"
          :text-color="msg.textColor"
          :bg-color="msg.bgColor"
          :name="msg.name"
          :avatarREMOVED="msg.avatar"
          :text="msg.text"
          :stamp="msg.stamp"
      />
      <div v-if="!comitente.messages || !comitente.messages.length">
        Nenhuma interação até o momento
      </div>
    </div>
    <div v-if="show" class="chat-input pos-rlt">
      <u-input type="textarea" class="bg-white text-black b-radius-3px" hide-underline autofocus v-model="message" />
      <u-btn icon="send" flat round size="xs" />
    </div>
  </div>
</template>

<script>
import {UChatMessage, UInput} from 'uloc-vue'

export default {
  name: 'ChatItem',
  components: {UChatMessage, UInput},
  props: {
    comitente: {
      required: true
    }
  },
  data () {
    return {
      show: false,
      message: null
    }
  },
  computed: {
    bindContainerStyle () {
      let css = []
      //if (this.show) {
      css.push({border: `2px ${this.comitente.color} solid`})
      //}
      return css
    },
    bindChatStyle () {
      let css = []
      if (!this.show) {
        css.push({background: this.comitente.color})
      }
      return css
    },
    bindNameStyle () {
      let css = []
      if (this.show) {
        css.push({color: this.comitente.color})
        css.push({fontWeight: 'bold'})
      }
      return css
    }
  }
}
</script>

<style lang="stylus">
  .comitente-chat-item {
    border-radius 5px
    margin-bottom 14px
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    overflow hidden
    // border: 2px transparent

    .cchat {
      // border-radius 5px
      color #FFFFFF
      font-size 13px
      font-weight 500
      display flex
      justify-content space-between
      align-items center
      height 33px
      padding 0 14px
      cursor pointer

      .chat-user {
        flex 1
      }

      .chat-msg-not-read {
        color #FFFFFF
        font-size 10px
        font-weight 500
        background #D41212
        display flex
        justify-content center
        align-items center
        padding 3px 5px
        border-radius 10px
        margin-right 10px
      }

      .chat-toggle {

      }
    }

    &.opened {
      background transparent !important

      .cchat {
      }

      .chat-toggle {
        color #666666 !important
      }
    }


    .message-container {
      display block
      max-height 300px
      overflow auto
      padding 12px
      font-size 12px
      background-color #e5ddd5

      .u-message-name{
        font-size 12px
        margin-bottom 2px
      }

      .u-message-label {
        margin 8px 0 !important
      }

      .u-message-stamp{
        font-size 11px
      }

      .u-message-sent{
        .u-message-text{
          color #dcf8c6
        }
      }

      .u-message-received{
        .u-message-name{
          font-weight bold
        }
        .u-message-text{
          color #f0f0f0
        }
      }
    }

    .chat-input{
      background-color #f0f0f0
      padding 5px
      position relative
      display flex
      flex-direction row
      align-items center
      .u-input{
        flex 1
        textarea{
          padding 2px 4px
          border #F2f2f2 1px solid
        }
      }
      .icon-send{
        position relative
        right 12px
      }
    }
  }
</style>
