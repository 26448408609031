<template>
  <div>
    <comitentes-online :leilao="parseProps.leilao" />
  </div>
</template>

<script>
import ComitentesOnline from './ComitentesOnline';
export default {
  name: 'MenuMonitorComitente',
  props: {
    parseProps: {
      required: true
    }
  },
  components: {ComitentesOnline}
}
</script>

<style scoped>

</style>
